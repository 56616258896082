import getConfig from 'next/config'

const apiUrl = process.env.NEXT_PUBLIC_API_BASE_URL ?? '/api'
const API_ENDPOINT = (endpoint: string) => `${apiUrl}${endpoint}`

export const USERS_ENDPOINT = API_ENDPOINT('/users')
export const USER_FIND_ENDPOINT = (q: string, category: 'nickname' | 'email' = 'nickname') => `${USERS_ENDPOINT}/find?q=${q}&category=${category}`
export const USER_ENDPOINT = (id: string) => `${USERS_ENDPOINT}/${id}`
export const USER_PROJECTS_ENDPOINT = (user: string) => `${USER_ENDPOINT(user)}/projects`
export const USER_ARTICLES_ENDPOINT = (user: string) => `${USER_ENDPOINT(user)}/articles`
export const USER_REVIEWS_ENDPOINT = (user: string) => `${USER_ENDPOINT(user)}/reviews`
export const USER_TEMPLATES_ENDPOINT = (user: string) => `${USER_ENDPOINT(user)}/templates`
export const USER_NEWLY_REGISTERED_ENDPOINT = (id: string) => `${USER_ENDPOINT(id)}/newly-registered`
export const USER_VERIFY_ENDPOINT = (id: string) => `${USER_ENDPOINT(id)}/verify`

export const PROJECTS_ENDPOINT = API_ENDPOINT('/projects')
export const PROJECT_ENDPOINT = (id: string) => `${PROJECTS_ENDPOINT}/${id}`
export const PROJECT_FILES_ENDPOINT = (id: string) => `${PROJECT_ENDPOINT(id)}/files`
export const QUERY_PROJECT_FILES_ENDPOINT = (id: string) => `${PROJECT_ENDPOINT(id)}/files/query`
export const PROJECT_FILES_WITH_LINKED_ARTICLES_ENDPOINT = (id: string) => `${PROJECT_ENDPOINT(id)}/files/linked`
export const PROJECT_DOCUMENT_ENDPOINT = (id: string, documentId: string) => `${PROJECT_ENDPOINT(id)}/files/documents/${documentId}`
export const PROJECT_BLOB_ENDPOINT = (id: string, blobId: string) => `${PROJECT_ENDPOINT(id)}/files/blob/${blobId}`

export const JOURNALS_ENDPOINT = API_ENDPOINT('/journals')
export const JOURNAL_ENDPOINT = (id: string) => `${JOURNALS_ENDPOINT}/${id}`
export const JOURNAL_THUMBNAIL_ENDPOINT = (id: string) => `${JOURNAL_ENDPOINT(id)}/thumbnail`
// export const JOURNAL_ARTICLES_ENDPOINT = (id: string) => `${JOURNAL_ENDPOINT(id)}/articles`
export const JOURNAL_REQUESTS_ENDPOINT = (id: string) => `${JOURNAL_ENDPOINT(id)}/requests`

export const DOCUMENTS_ENDPOINT = API_ENDPOINT('/documents')
export const DOCUMENT_ENDPOINT = (id: string) => `${DOCUMENTS_ENDPOINT}/${id}`
export const LINKED_ARTICLES_ENDPOINT = (documentId: string) => DOCUMENT_ENDPOINT(documentId) + '/articles'
export const DOCUMENT_REVISIONS_ENDPOINT = (documentId: string) => `${DOCUMENT_ENDPOINT(documentId)}/revisions`
export const DOCUMENT_REVISION_ENDPOINT = (documentId: string, revisionId: string) => `${DOCUMENT_REVISIONS_ENDPOINT(documentId)}/${revisionId}`
export const RESUBMIT_ARTICLE_ENDPOINT = (documentId: string) => `${DOCUMENT_ENDPOINT(documentId)}/resubmit`

export const FILES_ENDPOINT = API_ENDPOINT('/files')
export const FILE_ENDPOINT = (id: string) => `${FILES_ENDPOINT}/${id}`
export const FILE_PIN_ENDPOINT = (id: string) => `${FILE_ENDPOINT(id)}/pin`

export const ARTICLES_ENDPOINT = API_ENDPOINT('/articles')
export const ARTICLE_ENDPOINT = (id: string) => `${ARTICLES_ENDPOINT}/${id}`
export const ARTICLE_ASSIGN_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/assign`
export const ARTICLE_DEASSIGN_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/deassign`
export const ARTICLE_ACCEPT_REVIEW_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/accept-review`
export const ARTICLE_ACCEPT_REVIEW_PROJECT_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/accept-project`
export const ARTICLE_DECLINE_REVIEW_PROJECT_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/decline-project`
export const ARTICLE_STATUS_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/status`
export const ARTICLE_VOLUME_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/volume`
export const ARTICLE_TEMPLATE_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/template`
export const ARTICLE_ARCHIVE_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/archive`
export const ARTICLE_COMMENT_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/comment`
// export const ARTICLE_REVIEW_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/review`
export const ARTICLE_TIMELINE_ENDPOINT = (id: string) => `${ARTICLE_ENDPOINT(id)}/timeline`
export const ARTICLE_ATTACHMENTS_ENDPOINT = (articleId: string) => `${ARTICLE_ENDPOINT(articleId)}/attachments`
export const ARTICLE_ATTACHMENT_ENDPOINT = (articleId: string, fileId: string) => `${ARTICLE_ATTACHMENTS_ENDPOINT(articleId)}/${fileId}`
export const ARTICLE_REVIEW_SUBMIT_ENDPOINT = (articleId: string) => `${ARTICLE_ENDPOINT(articleId)}/review`
export const ARTICLE_REVIEWS_ENDPOINT = (articleId: string) => `${ARTICLE_ENDPOINT(articleId)}/reviews`
export const ARTICLE_REVIEWERS_ENDPOINT = (articleId: string) => `${ARTICLE_ENDPOINT(articleId)}/reviewers`
export const ARTICLE_REVIEWER_ENDPOINT = (articleId: string, userId: string) => `${ARTICLE_REVIEWERS_ENDPOINT(articleId)}/${userId}`
export const ARTICLE_REVIEWER_LOCK_ENDPOINT = (articleId: string, userId: string) => `${ARTICLE_REVIEWER_ENDPOINT(articleId, userId)}/lock`
export const ARTICLE_REVIEWER_ACCEPT_ENDPOINT = (articleId: string, userId: string) => `${ARTICLE_REVIEWER_ENDPOINT(articleId, userId)}/accept`
export const ARTICLE_REVIEWER_DOCUMENT_ENDPOINT = (articleId: string, userId: string) => `${ARTICLE_REVIEWER_ENDPOINT(articleId, userId)}/review-document`
export const ARTICLE_REVIEWER_RESTORE_ENDPOINT = (articleId: string, userId: string) => `${ARTICLE_REVIEWERS_ENDPOINT(articleId)}/${userId}/restore`

export const ARTICLE_REVIEW_ENDPOINT = (articleId: string, reviewId: string) => `${ARTICLE_REVIEWS_ENDPOINT(articleId)}/${reviewId}`
export const START_ARTICLE_REVIEW_ENDPOINT = (articleId: string) => ARTICLE_REVIEWS_ENDPOINT(articleId)
export const SUBMIT_ARTICLE_REVIEW_ENDPOINT = (articleId: string, reviewId: string) => `${ARTICLE_REVIEW_ENDPOINT(articleId, reviewId)}`
export const ARTICLE_REVIEW_THREADS_ENDPOINT = (articleId: string, reviewId: string) => `${ARTICLE_REVIEW_ENDPOINT(articleId, reviewId)}/threads`

export const AUTH_LOGIN_ENDPOINT = API_ENDPOINT('/login')

export const THREADS_ENDPOINT = API_ENDPOINT('/threads')
export const THREAD_ENDPOINT = (id: string) => `${THREADS_ENDPOINT}/${id}`
export const THREAD_RESTORE_ENDPOINT = (id: string) => `${THREAD_ENDPOINT(id)}/restore`
export const THREAD_COMMENTS_ENDPOINT = (id: string) => `${THREAD_ENDPOINT(id)}/comments`
export const DOCUMENT_THREADS_ENDPOINT = (id: string) => `${DOCUMENT_ENDPOINT(id)}/threads`
export const COMMENTS_ENDPOINT = API_ENDPOINT('/comments')
export const COMMENT_ENDPOINT = (id: string) => `${COMMENTS_ENDPOINT}/${id}`

export const NOTIFICATIONS_ENDPOINT = API_ENDPOINT('/notifications/@me')

export const PROJECT_MEMBERS_ENDPOINT = (projectId: string) => `${PROJECT_ENDPOINT(projectId)}/members`
export const PROJECT_MEMBER_ENDPOINT = (projectId: string, memberId: string) => `${PROJECT_MEMBERS_ENDPOINT(projectId)}/${memberId}`

export const COURSES_ENDPOINT = API_ENDPOINT('/courses')
export const COURSE_ENDPOINT = (id: string) => `${COURSES_ENDPOINT}/${id}`
export const COURSE_ATTEND_ENDPOINT = (id: string) => `${COURSE_ENDPOINT(id)}/attend`
export const COURSE_UNATTEND_ENDPOINT = (id: string) => `${COURSE_ENDPOINT(id)}/unattend`
export const COURSES_OWNED_ENDPOINT = `${COURSES_ENDPOINT}/owned`
export const COURSES_ATTENDING_ENDPOINT = `${COURSES_ENDPOINT}/attending`
export const COURSE_INVITE_ENDPOINT = (id: string) => `${COURSE_ENDPOINT(id)}/send-invitation`

export const TEMPLATES_ENDPOINT = API_ENDPOINT('/templates')
export const TEMPLATE_ENDPOINT = (id: string) => `${TEMPLATES_ENDPOINT}/${id}`

export const FORMS_ENDPOINT = API_ENDPOINT('/forms')
export const JOURNAL_FORMS_ENDPOINT = FORMS_ENDPOINT + '/journals'
export const VERIFICATION_FORM_ENDPOINT = FORMS_ENDPOINT + '/verification'

export const BLOBS_ENDPOINT = API_ENDPOINT('/blobs')
export const BLOB_CONTENT_ENDPOINT = (id: string) => `${BLOBS_ENDPOINT}/${id}` 
